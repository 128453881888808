<template>
  <quick-quote-page
    id="Estimators"
    :optionsname="$options.name"
  >
    <template #keyselection>
      <select-customer
        v-if="customers.data.length > 1"
        id="2-maintain"
        v-model="customers.selectedItem"
        :appendhint="`to view its ${displayAsPlural}`"
      />
    </template>

    <template #content>
      <estimator-table
        ref="maintenanceEstimatorTable"
      />
    </template>
  </quick-quote-page>
</template>

<script>
  import { get } from 'vuex-pathify'
  import QuickQuotePage from '@/components/QuickQuotePage'
  import EstimatorTable from '@/components/maintenance/tables/EstimatorTable'

  export default {
    name: 'EstimatorsView',

    components: {
      QuickQuotePage,
      EstimatorTable,
    },

    data () {
      return {}
    },

    computed: {
      users: get('user/users'),
      customers: get('customer/customers'),
      displayAsPlural: get('estimator/displayAsPlural'),
    },
  }
</script>
